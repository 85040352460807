class AppHeader extends Component {
	constructor(el) {
		super(el);
		this.searchOpener = this.$find('[data-search-opener]');
		this.loginOpener = this.$find('[data-login-opener]');
		this.navOpener = this.$find('[data-nav-opener]');
		this.addEventListeners();
	}

	openSearch() {
		EventBus.getInstance().dispatchEvent('showQuickSearchModal');
	}

	openLogin() {
		EventBus.getInstance().dispatchEvent('showBasePopup', 'login-popup');
	}

	addEventListeners() {
		this.searchOpener && this.searchOpener.addEventListener('click', () => this.openSearch());
		this.loginOpener && this.loginOpener.addEventListener('click', () => this.openLogin());
		this.navOpener.addEventListener('click', e => {
			e.stopPropagation();
			EventBus.getInstance().dispatchEvent('mainNavigationOpen');
		});
	}
}